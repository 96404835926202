@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");

.widget-spinner {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
}

.widget-block {
	width: 100%;
	font-size: 18px;
	font-family: "Lato", sans-serif;

	h1 {
		padding: 0;
		margin: 0;
	}
	h2 {
		padding: 0;
		margin: 0;
	}
	h3 {
		padding: 0;
		margin: 0;
	}
	h4 {
		padding: 0;
		margin: 0;
	}
	h5 {
		padding: 0;
		margin: 0;
	}
	h6 {
		padding: 0;
		margin: 0;
	}

	&--container {
		max-width: 1536px;
		margin: 0 auto;
		position: relative;

		@media (min-width: 600px) {
			max-width: 100%;
		}

		// Laptop için 3 sütun
		@media (min-width: 1024px) {
			max-width: 1024px;
		}

		// Masaüstü için 4 sütun
		@media (min-width: 1440px) {
			max-width: 1440px;
		}
	}

	&--header-title {
		font-size: 1.5em !important;
		width: 100%;
		text-align: center;
		font-weight: 500 !important;
		color: #36383d;
		padding-top: 0.625em;
		position: relative;

		&:after {
			content: "";
			display: block;
			width: 50px;
			height: 0.2em;
			background-color: var(--color);
			margin: 0.3em auto 0;
			border-radius: 16px;
		}
	}
	&--shoppingTitle {
		font-size: 1.5em !important;
		width: 100%;
		text-align: left;
		font-weight: 500 !important;
		color: #36383d;
		padding-top: 0.625em;
		position: relative;
	}
	&--speakers {
		display: grid;
		grid-template-columns: repeat(2, 1fr); // Mobilde 1 sütun
		padding: 0 1.25em;
		gap: 1em;

		// Tablet için 2 sütun
		@media (min-width: 600px) {
			grid-template-columns: repeat(3, 1fr);
		}

		// Laptop için 3 sütun
		@media (min-width: 1024px) {
			grid-template-columns: repeat(4, 1fr);
		}

		// Masaüstü için 4 sütun
		@media (min-width: 1440px) {
			grid-template-columns: repeat(4, 1fr);
		}

		&--item {
			display: flex;
			flex-direction: column;
			align-items: center;
			box-shadow: 0 2px 10px rgba(41, 47, 59, 0.1);
			border-radius: 0.8em;
			padding: 1.5em 1em;
			gap: 1em;
			border-bottom: 0.25em solid;
			border-color: var(--color);
			cursor: pointer;
			background: #fff;
			justify-content: space-between;

			&--image {
				width: 150px;
				height: 150px;
				overflow: hidden;
				display: flex;
				justify-content: center;
				cursor: pointer;

				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
					object-position: center;
					border-radius: 100%;
				}
			}
			&--header {
				width: 100%;
				display: flex;
				flex-direction: column;
				align-items: center;
				text-align: center;
				gap: 0.625em;
				&--name {
					color: #36383d;
					position: relative;
					text-align: center;
					width: 100%;
					cursor: pointer;

					&:after {
						content: "";
						display: block;
						width: 10%;
						height: 0.15em;
						background-color: var(--color);
						margin: 0.3em auto 0;
						border-radius: 16px;
					}
				}
				&--title {
					font-size: 0.75em;
					font-weight: 400;
					color: #797979;
				}

				&--companyName {
					font-size: 0.85em;
					font-weight: 600;
					color: #525252;
				}
			}
		}
		&--modal {
			background-color: #ffffff;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			width: 80%;
			max-width: 40%;
			padding: 1em;
			outline: none;
			border-radius: 16px;

			@media (max-width: 1024px) {
				// Tablet (örnek breakpoint)
				max-width: 70%;
			}

			@media (max-width: 768px) {
				// Mobil (örnek breakpoint)
				max-width: 80%;
			}

			&--speaker {
				&--container {
					max-height: calc(100vh - 180px) !important;
					overflow-x: hidden;
					overflow-y: auto;
				}
				&--header {
					display: flex;
					flex-direction: column;
					gap: 1em;
					justify-content: center;
					align-items: center;
					padding-top: 1.25em;
					&--avatar {
						width: 200px !important;
						height: 200px !important;
					}
					&--name {
						font-weight: 700 !important;
						font-size: 1.5em !important;
						font-family: "Lato", sans-serif !important;
						text-align: center;
					}
					&--companyName {
						font-size: 1em;
						font-weight: 600;
						color: #525252;
						font-family: "Lato", sans-serif !important;
					}
					&--social-media {
						display: flex;
						gap: 1;
					}
				}
				&--bio {
					font-family: "Lato", sans-serif;
					padding-left: 1em;
					padding-right: 1em;
					font-size: 1em;
					line-height: 1.25em;
					color: #797979;
				}
			}
		}
	}
	&--search-container {
		padding: 1em 1.25em;
	}
	&--search-not {
		display: flex;
		width: 100%;
		text-align: center;
		justify-content: center;
		font-size: 1em;
	}

	&--sponsors {
		padding-top: 2em;
		&--cat {
			padding-bottom: 3em;

			&--logos-container {
				width: 100%;
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;
				align-items: center;
				gap: 1em;
				justify-content: center;
				padding-top: 2em;
			}
			&--title {
				position: relative;
				text-align: center;
				margin-bottom: 4;
				border-bottom: 0.5px solid #d6d9d9;
				padding: 20px 0px;
				h4 {
					display: inline-block;
					padding: 0 20px;
					position: relative;
					z-index: 1;
					font-size: 1em;
					color: #36383d;
					font-weight: 600;
				}

				&--line {
					position: absolute;
					top: 50%;
					left: 0;
					right: 0;
					border-top: 2px solid #ccc;
					z-index: 0;
				}
			}

			&--logo {
				padding: 1em 1em;
				gap: 1em;
				width: 200px;
				height: 200px;
				background: #fff;
				box-shadow: 0 2px 10px rgba(41, 47, 59, 0.1);
				border-bottom: 0.25em solid;
				border-color: var(--color);
				border-radius: 0.8em;

				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
					object-position: center;
				}
			}
		}
	}
	&--program {
		padding: 0 1.25em;
		margin-top: 1em;

		&--search-container {
			padding: 1em 0em;
		}

		&--days {
			&--item {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				cursor: pointer;
				padding: 1em 0.625em;
				border-radius: 0.8em;

				//box-shadow: 0 2px 10px rgba(41, 47, 59, 0.1);

				&--date {
					font-size: 0.75em;
					padding-bottom: 0.75em;
				}
				&--name {
					font-size: 1em;
					font-weight: 600;
				}
			}
		}

		&--navigation {
			&--left {
				position: absolute !important;
				left: -30px;
				top: 50%;
				transform: translateY(-50%);
				@media (min-width: 1024px) {
					display: none !important;
				}
			}
			&--right {
				position: absolute !important;
				top: 50%;
				transform: translateY(-50%);
				right: -30px;
				@media (min-width: 1024px) {
					display: none !important;
				}
			}
		}

		&--saloons {
			padding-top: 1em;
			display: grid;
			grid-template-columns: repeat(
				4,
				minmax(0, 1fr)
			); // Masaüstü için varsayılan değer
			gap: 0.875em; // Aralarına boşluk eklemek için isteğe bağlı

			&--title {
				font-size: 0.875em;
			}

			@media (max-width: 1024px) {
				// Tablet (örnek breakpoint)
				grid-template-columns: repeat(3, minmax(0, 1fr));
			}

			@media (max-width: 768px) {
				// Mobil (örnek breakpoint)
				grid-template-columns: repeat(2, minmax(0, 1fr));
			}
		}

		&--session {
			padding-top: 0.5em;

			&--container {
				margin-top: 0.25em;
			}

			&--header-title {
				font-size: 1em !important;
				width: 100%;
				text-align: center;
				font-weight: 500 !important;
				color: #36383d;
				padding-top: 1em;
				position: relative;
				margin-top: 1.24em !important;

				&:after {
					content: "";
					display: block;
					width: 50px;
					height: 0.2em;
					margin: 0.3em auto 0;
					border-radius: 16px;
				}
			}
			&--items {
				box-shadow: 0 2px 10px rgba(41, 47, 59, 0.1);
				margin-bottom: 0.875em;
				padding: 0.625em 1em;
				border-radius: 16px;
				cursor: pointer;
				&--item {
					width: 100%;
					&--header {
						display: flex;
						justify-content: space-between;
						align-items: center;

						&--left {
							display: flex;
							gap: 0.75em;

							&--box {
								display: flex;
								gap: 0.125em;
							}
							span {
								font-size: 0.75em;
								color: #36383d;
							}
						}
						&--right {
							display: flex;
							gap: 0.125em;
							span {
								font-size: 0.75em;
								color: #36383d;
							}
						}
					}
					&--container {
						padding-top: 0.25em;
						padding-left: 0.125em;

						&--title {
							font-size: 1em;
							font-weight: 600;
						}

						&--speakers {
							padding-top: 0.25em;

							&--tooltip {
								display: flex;
								flex-direction: column;
								align-items: center;
								justify-content: center;
							}
							&--name {
								font-size: 1.125em;
								text-align: center;
							}
							&--title {
								padding-top: 0.125em;
								font-size: 1em;
								text-align: center;
							}
						}
					}
				}

				&--break {
					display: flex;
					align-items: center;

					&--container {
						padding-right: 1em;
					}
				}
			}
		}
		&--modal {
			background-color: #ffffff;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			width: 90%;
			max-width: 40%;
			padding: 1em;
			outline: none;
			border-radius: 16px;

			@media (max-width: 1024px) {
				// Tablet (örnek breakpoint)
				max-width: 70%;
			}

			@media (max-width: 768px) {
				// Mobil (örnek breakpoint)
				max-width: 80%;
			}

			&--speaker {
				&--container {
					max-height: calc(100vh - 180px) !important;
					overflow-x: hidden;
					overflow-y: auto;
					padding: 1em 1em;
					position: relative;

					&--sponsor {
						position: absolute;
						right: 1em;
					}
				}
				&--header {
					display: flex;
					flex-direction: column;
					gap: 1em;
					justify-content: center;
					align-items: center;
					padding-top: 1.25em;
					&--avatar {
						width: 200px !important;
						height: 200px !important;
					}
					&--name {
						font-weight: 700 !important;
						font-size: 1.5em !important;
						font-family: "Lato", sans-serif !important;
					}
					&--title {
						text-align: center;
					}

					&--social-media {
						display: flex;
						gap: 1;
					}
					&--bio {
						font-family: "Lato", sans-serif;
					}
				}
				&--bio {
					margin-top: 1em;
					box-shadow: 0 2px 10px rgba(41, 47, 59, 0.1);
					border-radius: 16px;
					padding: 0.5em 1em;
					p {
						font-family: "Lato", sans-serif !important;
						margin-top: 0;
					}
				}

				&--session-box {
					font-family: "Lato", sans-serif !important;
					box-shadow: 0 2px 10px rgba(41, 47, 59, 0.1);
					border-radius: 16px;
					margin-top: 1em;
					padding: 1em;
					display: flex;
					flex-direction: column;
					gap: 1em;
					&--item {
						background-color: #e3e8e8;
						padding: 0.75em;
						border-radius: 16px;

						&--header {
							display: flex;
							justify-content: space-between;
							align-items: center;
							gap: 1em;

							span {
								color: #818181;
								font-size: 0.75em;
							}

							svg {
								font-size: 1em;
							}

							&--left {
								display: flex;
								align-items: center;
								gap: 10px;

								&--item {
									display: flex;
									align-items: center;
									gap: 4px;
								}
							}

							&--right {
								display: flex;
								align-items: center;
								gap: 4px;
							}
						}

						&--body {
							display: flex;
							flex-direction: column;
							gap: 0.4em;
						}
					}
				}
			}

			&--session {
				&--container {
					display: flex;
					flex-direction: column;
					gap: 1em;
					margin-top: 1em;
					padding: 0 1em;
					&--header {
						display: flex;
						flex-direction: column;
						gap: 1.25em;
						align-items: center;
						background: #fff;
						border-radius: 16px;
						padding: 10px;
						box-shadow: 0 2px 10px rgba(41, 47, 59, 0.1);
						padding-top: 1.25em;
						padding-bottom: 1.25em;

						&--title {
							font-family: "Lato", sans-serif;
							font-size: 1em;
							font-weight: 600;
							color: #000;
							border-radius: 16px;
							padding: 0.75em;
							text-align: center;
						}
						&--saloon {
							display: flex;
							justify-content: center;
							align-items: center;
							gap: 0.25em;
							font-family: "Lato", sans-serif;
							font-size: 0.875em;
							color: #000;
							border-radius: 16px;
							background-color: #e3e8e8;
							padding: 0.75em;
							text-align: center;

							svg {
								font-size: 1.25em;
							}
						}

						&--date {
							display: flex;
							width: 100%;

							&--item {
								display: flex;
								flex-direction: column;
								width: 100%;
								align-items: center;
								gap: 5px;

								&--text {
									font-family: "Lato", sans-serif;
									font-size: 0.75em;
									color: #818181;
									font-weight: 600;
								}
								&--icon {
									svg {
										width: 30px;
										height: 30px;
										border-radius: 16px;
										padding: 10px;
										background-color: #e3e8e8;
									}
								}
							}
						}
					}
					&--content {
						background: #fff;
						border-radius: 16px;
						padding: 10px;
						box-shadow: 0 2px 10px rgba(41, 47, 59, 0.1);
						padding: 0.75em 1em;
						text-align: lef;
						font-family: "Lato", sans-serif;
						font-size: 0.875em;
						color: #818181;
					}

					&--speakers {
						background: #fff;
						border-radius: 16px;
						box-shadow: 0 2px 10px rgba(41, 47, 59, 0.1);
						padding: 0.75em 0.75em;
						display: flex;
						flex-direction: column;
						gap: 10px;

						&--item {
							padding: 0.75em;
							border-radius: 16px;
							background-color: #e3e8e8;
							cursor: pointer;

							&--name {
								font-family: "Lato", sans-serif;
								font-size: 0.75em;
								font-weight: 600;
							}
							&--title {
								font-family: "Lato", sans-serif;
								font-size: 0.75em;
								color: #818181;
							}
						}
					}
				}
			}
		}
	}

	&--form {
		padding-top: 1em;
		&--container {
			max-width: 1536px;
			margin: 0 auto;
			&--item {
				position: relative;

				&--popular {
					&--containerHorizon {
						position: absolute;
						display: inline-block;
						width: 80px;
						height: 80px;
						right: -20px;
						top: -40px;
						z-index: 9;
						&--discountTag {
							position: absolute;
							top: 0;
							left: 0;
							width: 100%;
							height: 100%;
							border-radius: 100%;
							box-shadow: 0px 0px 25px 13px rgba(0, 0, 0, 0.1);
						}

						&--title {
							position: absolute;
							top: 50%;
							left: 50%;
							transform: translate(-50%, -50%);
							color: #fff;
							font-size: 0.625em;
							text-align: center;
							pointer-events: none;
							font-weight: 600;
						}
					}
					&--container {
						position: absolute;
						display: inline-block;
						width: 80px;
						height: 80px;
						right: -20px;
						top: -40px;
						z-index: 9;
						&--discountTag {
							position: absolute;
							top: 0;
							left: 0;
							width: 100%;
							height: 100%;
							border-radius: 100%;
							box-shadow: 0px 0px 25px 13px rgba(0, 0, 0, 0.1);
						}

						&--title {
							position: absolute;
							top: 50%;
							left: 50%;
							transform: translate(-50%, -50%);
							color: #fff;
							font-size: 0.625em;
							text-align: center;
							pointer-events: none;
							font-weight: 600;
						}
					}
				}

				&--card {
					position: relative; // Sınır oluşturur
					overflow: hidden; // Taşmayı engeller
					z-index: 1; // Kendi z-index'ini belirler
					background-color: #fff;
					z-index: 1;
					display: flex;
					flex-direction: column;
					height: 360px;
					box-shadow: 0 2px 10px rgba(41, 47, 59, 0.1);
					border-radius: 16px;
					overflow: hidden;
					&--title {
						padding-top: 1em;
						padding-bottom: 1em;
						display: flex;
						justify-content: center;
						align-items: center;
						font-size: 0.875em;
					}

					&--header {
						max-height: 50px;
						display: flex;
						justify-content: center;
						align-items: center;
						padding: 20px 10px 20px 10px;
						border-bottom: 1px solid #ccc;
						&--title {
							font-size: 0.875em;
							text-align: center;
						}
					}

					&--option {
						margin: auto;
						width: 100%;

						&--item {
							display: flex;
							align-items: center;
							justify-content: space-between;
							padding: 0 1em;
							gap: 0.5em;

							&--price-box {
								display: flex;
								align-items: center;
								gap: 2em;
								width: 100%;
								justify-content: space-between;
								font-size: 0.85em;

								&--name {
									font-size: 1em !important;
								}
								&--price {
									font-size: 1em !important;
								}
							}
						}
					}
				}
			}
		}

		&--modal {
			background-color: #ffffff;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			width: 500px;
			max-width: 60%;
			padding: 1em;
			outline: none;
			border-radius: 16px;

			@media (max-width: 1024px) {
				// Tablet (örnek breakpoint)
				max-width: 50%;
			}

			@media (max-width: 768px) {
				// Mobil (örnek breakpoint)
				max-width: 50%;
			}

			&--details {
				&--container {
					max-height: calc(100vh - 180px) !important;
					overflow-x: hidden;
					overflow-y: auto;
					font-family: "Lato", sans-serif;
					padding-left: 1em;
					padding-right: 1em;
				}

				&--close {
					padding-left: 1em;
					padding-right: 1em;
					display: flex;
					justify-content: space-between;
					align-items: center;
				}
			}

			&--description {
				margin-top: 2em;
				text-align: left;
				width: 100%;
				overflow-y: auto;
				font-family: "Lato", sans-serif;
				font-size: 0.875em;

				ol {
					padding-left: 15px;
					list-style: none;
				}

				ol li {
					position: relative;
					padding-left: 25px;
					margin-bottom: 10px;
					border-bottom: 1px solid #e3e8ef;
					padding-bottom: 12px;

					&:before {
						content: "";
						position: absolute;
						left: -5px;
						top: 5px;
						width: 20px;
						height: 20px;
						background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="green"><path d="M9 19L2 12l1.41-1.41L9 16.17l11.59-11.59L22 6l-13 13z"/></svg>');
						background-size: contain;
						background-repeat: no-repeat;
					}
				}
			}
		}

		&--cart {
			max-width: 1536px;
			margin: 0 auto;
			display: flex;
			justify-content: end;

			&--icon {
				position: absolute;
				display: inline-block;
				right: 0;
				top: 0;
			}

			&--container {
				margin-top: 20px;
				&--popper {
					background-color: #fff;
					z-index: 999;

					&--header-title {
						font-size: 0.875em;
						font-weight: bold;
					}

					&--cartList {
						height: 100%;
						max-height: calc(100vh - 205px);
						overflow-x: hidden;

						&--container {
							width: "100%";

							&--header {
								display: flex;
								flex-direction: column;
							}

							&--title {
								width: 100%;
								font-size: 0.875em;
								font-weight: 600;
								color: #121926;
							}

							&--subtitle {
								font-size: 0.725em;
								font-weight: 600;
								color: #697586;
							}

							&--fee {
								font-size: 0.725em;
							}
						}
					}

					&--footer-title {
						font-size: 0.875em;
						font-weight: bold;
					}

					&--footer-link {
						font-size: 0.875em !important;
						font-weight: bold;
						text-decoration: none !important;
						color: #121926 !important;
						background-color: #36383d;
						padding: 0.5em 0.875em;
						color: #fff !important;
						border-radius: 4px;

						&:hover {
							background-color: #52555c;
						}
					}
				}

				&--horizontal {
					&--header {
						border-top-left-radius: 0.8em;
						border-top-right-radius: 0.8em;
						padding: 10px 20px;
					}
					&--items {
						border-radius: 0.8em;
						margin-bottom: 1em;
						background-color: #fff;
						box-shadow: 0 2px 10px rgba(41, 47, 59, 0.1);

						&--header {
							display: flex;
							justify-content: space-between;
							align-items: center;
							padding: 20px 10px 20px 20px;
							border-bottom: 1px solid #ccc;
							&--title {
								font-size: 1em;
								font-weight: 700;
							}

							&--right {
								display: flex;
								align-items: center;
								gap: 1em;
								&--price {
									background-color: #e0e0e0;
									border-radius: 4px;
									padding: 6px 12px;
								}
							}
						}
						&--price-periods {
							border-radius: 0.8em;
							background-color: #e0e0e0;
							display: flex;
							align-items: center;
							justify-content: space-between;
							padding: 20px 10px 20px 20px;
							&--right {
								padding-right: 10px;
							}
						}
						&--children {
							position: relative; // Sınır oluşturur
							overflow: hidden; // Taşmayı engeller
							z-index: 1; // Kendi z-index'ini belirler
							background-color: #fff;
							z-index: 1;
							display: flex;
							flex-direction: column;
							padding: 20px 10px 20px 20px;
							border-radius: 16px;
							overflow: hidden;

							&--header {
								max-height: 50px;
								display: flex;
								justify-content: left;
								align-items: center;
								padding: 20px 10px 20px 10px;
								border-bottom: 1px solid #ccc;
								&--title {
									font-size: 0.875em;
									font-weight: 700;
								}
							}
							&--item-container {
								padding-top: 20px;
								display: flex;
								flex-direction: column;
								gap: 1em;
								&--item {
									border-radius: 0.8em;
									background-color: #e0e0e0;
									display: flex;
									align-items: center;
									justify-content: space-between;
									padding: 20px 10px 20px 20px;
								}
							}
						}
					}
				}
			}
		}
		&--headerImgaeContainer {
			padding-bottom: 2em;

			&--image {
				box-shadow: 0 2px 10px rgba(41, 47, 59, 0.1);
				border-radius: 16px;
				overflow: hidden;
				width: 100%;
			}
		}
		&--headerDescriptionContainer {
			padding-bottom: 1em;
			padding-left: 1em;
			padding-right: 1em;

			p {
				font-size: 0.875em;
			}
		}
		&--footerImgaeContainer {
			padding-top: 2em;
		}
	}
	.ql-align-center {
		text-align: center;
	}
	.ql-align-right {
		text-align: right;
	}
	.ql-align-justify {
		text-align: justify;
	}
	.ql-align-left {
		text-align: left;
	}

	&--horizontal {
		margin-top: 40px;
	}

	&--faq {
		margin-top: 1em;
		&--container {
			margin: 0 0 !important;
		}

		&--content-container {
			&--content {
				p {
					margin-top: 0 !important;
					margin-bottom: 0 !important;
					padding-bottom: 0.85em;
					font-size: 0.85em;
				}
			}
		}
	}
}
